

.oldheader {
    font-family: Poppins;
    font-weight: 700;
    font-size: 1.3rem;
    color: #29378B;
}
.oldenoughnextheader {
    font-family: Poppins;
    font-weight: 700;
    font-size: 1.3rem;
    color: #29378B;

}
.oldenoughnextsubheader  {
    font-family: Poppins;
    font-size: 0.9rem;
    font-weight: 700;

}

.oldsubheader {
    font-family: Poppins;
    font-size: 1.3rem;
}

@media (min-width: 768px) {
    .oldsubheader {
        font-size: 1.1rem;
    }

    .oldheader {
        font-size: 1.3rem;
    }
}
