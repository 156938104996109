
body {}
.custom-header {
    font-family: Poppins;
     color: #29378B;
     font-weight: 700;
     font-size: 1.3rem;
}



.custom-body-entry {
    font-family: Poppins;
    font-weight: 700;
    font-size: 0.9rem;
}

@media (min-width: 768px) {
    .custom-header {
        font-family: Poppins;
         color: #29378B;
         font-weight: 700;
         font-size: 1.8rem;
    }
    .custom-body-entry {
        font-family: Poppins;
        font-weight: 700;
        font-size: 1.2rem;
    }
}