.custom-card-text-circle {
    font-family: Poppins;
    font-size: 2.5rem;
    
}

.oldsubheader {
    font-family: Poppins;
    font-size: 1.5rem;
    font-weight: 700;

}

.cardheadertxt {
    font-size: 2.0rem;
    font-family: Poppins;
    color: #29378B;
    font-weight:700;
} 
.custom-button {
    background-color: #29378B;
    border: none;
}



@media(max-width: 768px) {
.cardheadertxt {
    font-size: 1.2rem; 
}
    .custom-card-text {
        font-size: 0.8rem;

    }
    .oldsubheader {
        font-family: Poppins;
        font-size: 1    rem;
        font-weight: 700;
    
    }
    
}
