
.custom-card {
    border: none !important; 
    
    
}



.custom-card-text .form-check-label {
    font-family: Poppins;
    font-size: 1.3rem !important;
}


.cardheadertxt {
    font-size: 2.0rem;
    font-family: Poppins;
    color: #29378B;
    font-weight:700;
} 


@media(max-width: 768px) {
.cardheadertxt {
    font-size: 1.3rem; 
}
.custom-card-text .form-check-label {
    font-family: Poppins;
    font-size: 1rem !important;
}
    
}
