.custom-card {
border: none !important; 

    
}


.custom-text {
    font-family: Poppins;
    font-size: 0.7rem;
    font-weight:

}

.form-check-label {
    font-size: 0.8rem;
}

.formfooter {
    font-size: 0.6rem;
    font-family: Poppins;
    font-weight: 700;
}


@media (min-width: 768px) {
    .custom-text {
        font-family: Poppins;
        font-size: 1.2rem; 
    font-weight: 400; }
        
        
    
}
