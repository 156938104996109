.oldheader {
    font-family: Poppins;
    color: #29378B;
    font-weight: 700;
    font-size: 1.3rem;
}

.oldsubheader {
    font-family: Poppins    ;
    font-size: 0.9rem;
    font-weight: 700;

}


@media (min-width: 768px) {
    .oldsubheader {
        font-size: 1.1rem;
    
    }
    .oldheader {
        font-size: 1.8;
    }

    

}