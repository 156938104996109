.footer-txt {
    font-size: 0.6rem;
    font-family: Poppins;
    font-weight: 700;
    color: #29378B;
}

.footer-logo {
    height: 50px;
}

.custom-background {
    background-color: #FAF9F6 !important;
}

@media (min-width: 768px) { 
    .footer-txt {
        font-size: 1.2rem; 
    }

    .footer-logo {
        height: 80px;
    }   
}
